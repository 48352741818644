import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-solid-svg-icons'
import Img from "gatsby-image"

const HowWrapper = styled.section`
  width: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  ${({theme}) => theme.media.desktop} {
    padding: 0 1rem;
  }
`;

const HelpWrapper = styled.div`
  width: 100%;
  background: ${({isSecond}) => isSecond ? 'rgba(31,120,156, .1)' : 'rgba(70,186,232, .1)'};
  margin: 2rem 0;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: ${({isSecond}) => isSecond ? '55% 1fr' : '1fr 55%'};
  }

  ${({theme}) => theme.media.tabletXL} {
    max-width: 900px;
    margin: 2rem auto;
  }

  ${({theme}) => theme.media.laptop} {
    max-width: 1000px;
  }

  ${({theme}) => theme.media.laptopXL} {
    max-width: 1200px;
    margin: 5rem auto;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
  }
`;

const TextBox = styled.div`
  position: relative;
  padding: 1.5rem;

  ${({theme}) => theme.media.tablet} {
    padding: 2.5rem 3rem 2.5rem 2.5rem;
    order: ${({isRight}) => isRight ? '1' : '0'};
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3.5rem 6rem 3.5rem 3.5rem;

    ::before {
      position: absolute;
      content: '';
      top: ${({isRight}) => isRight ? '-1rem' : '100%'};
      right: 0;
      width: 100%;
      height: 1rem;
      background: ${({theme}) => theme.colors.mainBlack};
    }

    ::after {
      position: absolute;
      content: '';
      top: ${({isRight}) => isRight ? '-1rem' : '15%'};
      right: ${({isRight}) => isRight ? '-1rem' : '100%'};
      width: 1rem;
      height: calc(85% + 1rem);
      background: ${({theme}) => theme.colors.mainBlack};
    }
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 3.5rem 8rem 3.5rem 3.5rem;
  }
`;

const Span = styled.span`
  position: absolute;
  top: -3%;
  right: 5%;
  font-size: 4rem;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.laptop} {
    font-size: 5rem;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  margin: .7rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  svg {
    margin-right: .5rem;
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.2rem;
  }
`;

const ImgBox = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 100%;

    div {
      height: 100%;
    }
  }
`;

const WrapperText = styled.div`
  width: 100%;
  /* max-width: 1400px; */
  margin: 0 auto;

  ${({theme}) => theme.media.laptop} {
    max-width: 1000px;
  }

  ${({theme}) => theme.media.laptopXL} {
    max-width: 1200px;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
  }

  ${({theme}) => theme.media.desktopXL} {
    max-width: 1400px;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.2;
  margin-bottom: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
    padding-right: 20rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding-right: 40rem;

  ${({theme}) => theme.media.desktopXL} {
    padding-right: 50rem;
  }
`;

// const ButtonWrapper = styled.div`
//   width: 100%;

//   ${({theme}) => theme.media.laptopXL} {
//     display: flex;
//     padding: .4rem 0;
//   }
// `;

// const Button = styled.button`
//   font-family: ${({theme}) => theme.font.mainFont};
//   display: inline-block;
//   text-decoration: none;
//   padding: .3rem 1rem;
//   margin: .5rem;
//   color: ${({theme}) => theme.colors.mainBlack};
//   border: 2px solid ${({theme}) => theme.colors.mainBlack};
//   letter-spacing: 1px;
//   font-weight: 500;
//   box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainYellow};
  
//   svg {
//     color: ${({theme}) => theme.colors.mainBlack};
//   }

//   ${({theme}) => theme.media.laptop} {
//     padding: .4rem 1.3rem;
//     font-size: 1.1rem;
//   }

//   ${({theme}) => theme.media.laptopXL} {
//     padding: .4rem 1rem;
//   }

//   ${({theme}) => theme.media.desktop} {
//     padding: .4rem 1.3rem;
//   }
// `;


const HowAssistance = () => {
  const data = useStaticQuery(graphql`
    query {
      installAny01: file(relativePath: { eq: "anydesk-guide-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      installAny02: file(relativePath: { eq: "anydesk-guide-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          anyDeskWin
          anyDeskMac
        }
      }
    }
  `)

  return (
    <HowWrapper>
      <InnerWrapper>
        <WrapperText>
          <H2>Instrukcja <span>uruchomienia</span> AnyDesk'a</H2>
        </WrapperText>
      
        <HelpWrapper>
          <TextBox isRight="isRight">
            <Span>1</Span>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700">Rozpocznij korzystać z AnyDesk w dwóch krokach, to bardzo proste.</Text>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900"><FontAwesomeIcon icon={faCheckSquare}/>Pobierz odpowiednią wersję Anydesk, w zależności jaki posiadasz system operacyjny na komputerze.</Text>
            {/* <ButtonWrapper data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">
              <Button as="a" href={data.site.siteMetadata.anyDeskWin} target="_blank" download><FontAwesomeIcon icon={faFileDownload}/> AnyDesk Windows</Button>
              <Button as="a" href={data.site.siteMetadata.anyDeskMac} target="_blank" download><FontAwesomeIcon icon={faFileDownload} /> AnyDesk macOS</Button>
            </ButtonWrapper> */}
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100"><FontAwesomeIcon icon={faCheckSquare}/>Kliknij dwukrotnie w ściągnięty plik AnyDesk, wtedy AnyDesk uruchomi się natychmiast.</Text>
          </TextBox>
          <ImgBox>
            <Img fluid={data.installAny01.childImageSharp.fluid} alt="instalacja AnyDesk"/>
          </ImgBox>
        </HelpWrapper>

        <HelpWrapper isSecond="isSecond">
          <TextBox>
            <Span>2</Span>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700"><FontAwesomeIcon icon={faCheckSquare}/>Po uruchomieniu się programu w górnym lewym rogu znajduje się 9-cio cyfrowe ID Twojego stanowiska pracy.</Text>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900"><FontAwesomeIcon icon={faCheckSquare}/>Następnie w rozmowie telrfonicznej proszę podać to ID naszemu informatykowi, aby mógł nawiązać połączenie z Twoim komputerem.</Text>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100"><FontAwesomeIcon icon={faCheckSquare}/>Po chwili pojawi się okienko z proźbą akceptacji połączenie, co należy uczynić.</Text>
            <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100"><FontAwesomeIcon icon={faSmile}/>Od tej chwili nasz pracownik IT może szybko rozwiązać Twój problem.</Text>
          </TextBox>
          <ImgBox>
            <Img fluid={data.installAny02.childImageSharp.fluid} alt="ID stanowiska pracy" />
          </ImgBox>
        </HelpWrapper>

      </InnerWrapper>
    </HowWrapper>
  )
}

export default HowAssistance