import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple } from '@fortawesome/fontawesome-free-brands'
import { faWindows } from '@fortawesome/fontawesome-free-brands'

import backGround from '../../images/remont-desktop.jpg';


const HeaderWrapper = styled.header`
  width: 100%;

  ${({theme}) => theme.media.tabletXL} {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 70% 1fr;
  }

  ${({theme}) => theme.media.laptop} {
    padding-bottom: 6rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    grid-template-columns: 65% 1fr;
  }

  ${({theme}) => theme.media.desktop} {
    padding-bottom: 5rem;
    grid-template-columns: 60% 1fr;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 3rem 0 2rem;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.laptop} {
    margin-top: 0;
    padding: 5rem 0 2rem 4rem;
  }
`;

const TextBox = styled.div`
  width: 100%;
  padding: 0 1rem;

  ${({theme}) => theme.media.tablet} {
    padding: 0 3rem;
    width: 85%;
  }

  ${({theme}) => theme.media.tabletXL} {
    width: 100%;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 3rem 0 3rem 11rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 3rem 0 3rem 20rem;
    width: 75%;
  }
`;

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainBlack};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  ${({theme}) => theme.media.tablet} {
    font-size: calc(4.31818vw + 25.18182px);
  }

  ${({theme}) => theme.media.laptop} {
    line-height: 1.1;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainWhite};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainYellow};
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 2rem 1rem;

  ${({theme}) => theme.media.tablet} {
    width: 80%;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
    padding: 2rem 3rem 2rem 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 2rem 5rem 2rem 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 2rem 10rem 2rem 1rem;
    font-size: 1.3rem;
  }
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1rem;
  margin: 0 0 1rem 1rem;
  color: ${({theme}) => theme.colors.mainWhite};
  border: 2px solid ${({theme}) => theme.colors.mainBlack};
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainYellow};
  background: ${({theme}) => theme.colors.mainBlue};

  svg {
    color: ${({theme}) => theme.colors.mainBlack};
    font-size: 1.4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: .5rem 1.5rem;
    font-size: 1.3rem;
  }
`;

const ImageBox = styled.div`
  display: none;

  ${({theme}) => theme.media.tabletXL} {
    display: block;
    background-image: url(${backGround});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  ${({theme}) => theme.media.laptop} {
    transform: scale(1.05);
  }

  ${({theme}) => theme.media.desktop} {
    transform: scale(1.1);
  }
`;


const HeaderAssistance = () => {
  const data = useStaticQuery(graphql`
    query Win {
      site {
        siteMetadata {
          anyDeskWin
          anyDeskMac
        }
      }     
    }
  `)

  return (
    <HeaderWrapper>
      <InnerWrapper>

        <TextBox>
          <H1>Jeśli potrzebujesz <span>pomocy</span></H1>
          <HeaderP>Wystarczy pobrać AnyDesk odpowiedni do twojego systemu operacyjnego. Resztą zajmiemy sie my.</HeaderP>
          <Button as="a" href={data.site.siteMetadata.anyDeskWin} download><FontAwesomeIcon icon={faWindows}/> AnyDesk Windows</Button>
          <Button as="a" href={data.site.siteMetadata.anyDeskMac} download><FontAwesomeIcon icon={faApple} /> AnyDesk macOS</Button>
        </TextBox>

      </InnerWrapper>

      <ImageBox>
      </ImageBox>

    </HeaderWrapper>
  )
}

export default HeaderAssistance