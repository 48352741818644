import React from 'react'

import HeaderAssistance from '../components/assistanceComponents/headerAssistance'
import HowAssistance from '../components/assistanceComponents/howAssistance'

import SEO from "../components/seo"

const Assistance = () => {
  return (
    <>
      <SEO 
        title="Pomoc zdalna, wystarczy AnyDesk."
        description="Jeżeli potrzebujesz szybkiej pomocy, nasi informatycy są w stanie pomuc Ci zdalnie."
      />

      <HeaderAssistance />
      <HowAssistance />

    </>
  )
}

export default Assistance